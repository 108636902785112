import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function BodyFat() {
  const body = useSelector((state) => state.body);
  const [weight, setWeight] = useState(true);
  const [waist, setWaist] = useState(false);
  const [chest, setChest] = useState(false);
  const [hips, setHips] = useState(false);
  const [arms, setArms] = useState(false);
  const [neck, setNeck] = useState(false);
  var bodyData = [];
  const fillData = () => {
    let objectTest = {};
    if (body.length > 0) {
      for (let i = 0; i < body.length; i++) {
        objectTest["id"] = body[i].id;
        objectTest["weight"] = body[i].weight;
        objectTest["date"] = body[i].date;
        objectTest["neck"] = body[i].neck;
        objectTest["arms"] = body[i].arms;
        objectTest["hips"] = body[i].hips;
        objectTest["chest"] = body[i].chest;
        objectTest["waist"] = body[i].waist;
        bodyData.push(objectTest);
        objectTest = {};
      }
    }
  };
  fillData();
  
  return (
    <div className="h-[250px] w-screen md:mb-8 mb-24">
      <h4 className="text-gray-500 text-xl ">Graph</h4>
      <div className="flex flex-wrap px-12 gap-4 justify-between md:px-24 md:mt-6 md:mb-4 text-xl">
        <div className="flex flex-col md:space-y-2">
          <label>Weight</label>
          <input
            type="checkbox"
            checked={weight}
            onChange={() => setWeight(!weight)}
          />
        </div>
        <div className="flex flex-col md:space-y-2">
          <label>Waist</label>
          <input type="checkbox" onChange={() => setWaist(!waist)} />
        </div>
        <div className="flex flex-col md:space-y-2">
          <label>Chest</label>
          <input type="checkbox" onChange={() => setChest(!chest)} />
        </div>
        <div className="flex flex-col md:space-y-2">
          <label>Hips</label>
          <input type="checkbox" onChange={() => setHips(!hips)} />
        </div>
        <div className="flex flex-col md:space-y-2">
          <label>Arms</label>
          <input type="checkbox" onChange={() => setArms(!arms)} />
        </div>
        <div className="flex flex-col md:space-y-2">
          <label>Neck</label>
          <input type="checkbox" onChange={() => setNeck(!neck)} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={bodyData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          width="100%"
          height="100%"
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          {weight ? (
            <Area
              type="monotone"
              dataKey="weight"
              stroke="#8884d8"
              fillOpacity={1}
              fill="#8884d8"
            />
          ) : (
            ""
          )}

          {waist ? (
            <Area
              type="monotone"
              dataKey="waist"
              stroke="#10b5b0"
              fillOpacity={0.4}
              fill="#10b5b0"
            />
          ) : (
            ""
          )}
          {chest ? (
            <Area
              type="monotone"
              dataKey="chest"
              stroke="#0fb825"
              fillOpacity={0.4}
              fill="#0fb825"
            />
          ) : (
            ""
          )}
          {hips ? (
            <Area
              type="monotone"
              dataKey="hips"
              stroke="#bf900f"
              fillOpacity={0.4}
              fill="#bf900f"
            />
          ) : (
            ""
          )}
          {arms ? (
            <Area
              type="monotone"
              dataKey="arms"
              stroke="#5e5c5c"
              fillOpacity={0.3}
              fill="#5e5c5c"
            />
          ) : (
            ""
          )}
          {neck ? (
            <Area
              type="monotone"
              dataKey="neck"
              stroke="#eb4034"
              fillOpacity={0.2}
              fill="#eb4034"
            />
          ) : (
            ""
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
