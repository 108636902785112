import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { motion } from "framer-motion";
import LoginModal from "./loginModal";
import RegisterModal from "./registerModal";
import Snackbar from "./Snackbar/Snackbar";
import UserNav from "./userNav";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import { logoutClassTime } from "../redux/classTimeSlice";
import { logoutBookings } from "../redux/bookingsSlice";
import { logoutBody } from "../redux/bodySlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Navbar({ logged }) {
  const [hover, setHover] = useState(false);
  const [click, setClick] = useState(false);
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const snackbarRef = useRef(null);
  const navigate = useNavigate();
  const s2 = useRef();
  useEffect(() => {
    if (success) {
      s2.current.show();
      setSuccess(false);
    }
  }, [success]);

  const handleLogout = (e) => {
    e.preventDefault();
    const result = axios
      .post(
        "https://api.barbox.gr/api/web/user/logout",
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(logout());
          dispatch(logoutClassTime());
          dispatch(logoutBookings());
          dispatch(logoutBody());
          navigate("/");
        }
      })
      .catch((error) => {
        dispatch(logout());
        dispatch(logoutClassTime());
        dispatch(logoutBookings());
        dispatch(logoutBody());
        navigate("/");
      });
  };
  return (
    <>
      <header
        className="sticky top-0 flex items-start justify-between max-w-7xl mx-auto xl:items-center p-5 "
        style={{ zIndex: 20 }}
      >
        <motion.div
          className="my-auto p-4 z-20"
          initial={{ x: -500, opacity: 0, scale: 0.5 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        >
          <button
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            onMouseEnter={() => !click && setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setClick(!click)}
          >
            <b
              style={{
                backgroundColor: "black",
                width: "40px",
                height: 3,
                display: "block",
                cursor: "pointer",
                borderRadius: 40,
                transition: "width .2s ease 0s,transform .3s ease",
              }}
            ></b>
            <b
              style={{
                backgroundColor: "black",
                width: !hover ? "40px" : 20,
                marginTop: 10,
                height: 3,
                borderRadius: 40,
                display: "block",
                cursor: "pointer",
                transition: "width .2s ease 0s,transform .3s ease",
              }}
            ></b>
          </button>
        </motion.div>
        <div className="item-end justify-end content-end"></div>
        <motion.div
          className="text-end px-4 my-auto inline-block "
          initial={{ x: +500, opacity: 0, scale: 0.5 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        >
          {logged ? (
            <UserNav />
          ) : (
            <>
              <a
                className="p-4 text-gray-600 cursor-pointer hover:text-gray-900 "
                onClick={() => setLogin(true)}
              >
                Login
              </a>
              <a
                className="p-4 text-gray-600 cursor-pointer hover:text-gray-900"
                onClick={() => setRegister(true)}
              >
                Register
              </a>
            </>
          )}
        </motion.div>
      </header>
      <div
        className={`h-screen w-[300px] top-0 fixed bg-gray-900 ${
          click ? "translate-x-0" : "translate-x-[-1000px]"
        } ease-in-out duration-700 z-30`}
      >
        <div className="top-[20px] absolute w-full text-center gap-16 z-10 h-screen">
          <div>
            <IoIosArrowBack
              style={{
                color: "white",
                position: "absolute",
                right: 30,
                cursor: "pointer",
                zIndex: 40,
              }}
              size={35}
              onClick={() => setClick(false)}
            />
          </div>
          <div className="flex flex-col justify-center h-full items-center space-y-12">
            <div>
              <a
                className="text-gray-300 text-2xl hover:text-red-400 cursor-pointer ease duration-500"
                onClick={() => navigate("/")}
              >
                Home
              </a>
            </div>
            <div>
              <a
                className="text-gray-300 text-2xl hover:text-red-400 cursor-pointer ease duration-500"
                onClick={() => navigate("/settings")}
              >
                Settings
              </a>
            </div>

            <div>
              <a
                className="text-gray-300 text-2xl hover:text-red-400 cursor-pointer ease duration-500"
                onClick={() => navigate("/history")}
              >
                History
              </a>
            </div>
            <div>
              <a
                className="text-gray-300 text-2xl hover:text-red-400 cursor-pointer ease duration-500"
                onClick={(e) => handleLogout(e)}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        isOpen={login}
        setIsOpen={setLogin}
        setSuccess={setSuccess}
        setMessage={setMessage}
      />
      <RegisterModal
        isOpen={register}
        setIsOpen={setRegister}
        snackRef={snackbarRef}
      />
      <Snackbar type="success" message={message} ref={s2} />
      <Snackbar
        type="success"
        message="User created successfully!"
        ref={snackbarRef}
      />
    </>
  );
}
