import React, { useMemo, useState } from "react";
import "../css/courses.css";
import { GrPrevious, GrNext } from "react-icons/gr";
import WodCard from "./wod/wodCard";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
function WodCardSingle({
  wod,
  index,
  active,
  setActive,
  setSuccess,
  setError,
  setMessage,
}) {
  const cleanHTML = DOMPurify.sanitize(wod.body, {
    USE_PROFILES: { html: true },
  });
  return (
    <motion.div
      className={`${
        active === index ? "block" : "hidden"
      } py-12 w-full min-h-[750px] max-h-[900px]`}
      initial={{ x: -200, opacity: 0 }}
      transition={{
        duration: 1.2,
      }}
      whileInView={{ opacity: 1, x: 0 }}
    >
      <h2 className="text-2xl tracking-widest text-center self-center basis-full text-white">
        {wod.title}
      </h2>

      <h3 className="text-white">{wod.date}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: cleanHTML }}
        className="mt-4 text-white"
      ></div>
    </motion.div>
  );
}
export default function WodPanel() {
  const [active, setActive] = useState(0);
  const wods = useSelector((state) => state.wod);
  const [data, setData] = useState([]);
  const dataPrevious = useMemo(() => {
    let arr = [];
    for (let i = 0; i < wods.length; i++) {
      const date = new Date();
      date.setHours(0, 0, 0);
      const wodDate = wods[i].date.split("/");
      const wodDate2 = new Date(
        Number(wodDate[2]),
        Number(wodDate[1]) - 1,
        Number(wodDate[0])
      );
      if (wodDate2 === date) {
        setActive(i);
        arr.push(wods[i]);
      } else if (wodDate2 > new Date()) {
        continue;
      } else {
        arr.push(wods[i]);
      }
    }
    return arr.sort(function (a, b) {
      var aa = a.date.split("/").reverse().join(),
        bb = b.date.split("/").reverse().join();
      return aa > bb ? -1 : aa < bb ? 1 : 0;
    });
  }, [wods]);
  return (
    <div className="2xl:h-screen flex  flex-col space-y-8  justify-center text-center overflow-hidden">
      <h4 className="title md:w-1/2 w-full md:px-0 px-12 mx-auto block text-2xl">
        WOD Panel
      </h4>
      <div className="flex flex-row justify-center items-center content-center text-center mt-8 gap-12">
        <div>
          <GrPrevious
            className="md:mx-auto block cursor-pointer"
            onClick={() => {
              if (active + 1 > wods.length - 1) {
                setActive(0);
              } else {
                setActive(active + 1);
              }
            }}
          />
        </div>
        <div className="h-full md:w-1/2 w-full bg-red-500 rounded-lg">
          {dataPrevious.map((wod, index) => {
            return (
              <WodCardSingle
                wod={wod}
                active={active}
                setActive={setActive}
                index={index}
              />
            );
          })}
        </div>
        {/* <WodCard /> */}

        <div>
          <GrNext
            className="mx-auto block cursor-pointer"
            onClick={() => {
              if (active - 1 < 0) {
                setActive(0);
              } else {
                setActive(active - 1);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
