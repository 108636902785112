import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    getAllNotifications: (state, action) => {
      return action.payload;
    },
    addNotification: (state, action) => {
      return [...state, action.payload];
    },
    updateNotification: (state, action) => {
      return state.map((item, index) => {
        return action.payload.id === item.id
          ? {
              ...action.payload.post,
            }
          : item;
      });
    },
    deleteNotification: (state, action) => {
      console.log(action.payload);
      return state.filter((element) => element.id !== action.payload);
    },
    logoutposts: (state, action) => {
      return [];
    },
  },
});

export const { getAllNotifications, addNotification, logoutposts, deleteNotification, updateNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
