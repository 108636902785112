import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../axiosInstance";
import { addBooking } from "../../redux/bookingsSlice";
export default function SingleButton({
  day,
  classTimes,
  weekStart,
  number,
  setError,
  setSuccess,
  setMessage,
}) {
  const [open, setOpen] = useState(false);
  const classes = classTimes.filter((cl) => cl.days === day);
  const [date2, setDate] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const submitBook = (cl) => {
    const formData = new FormData();
    formData.append("date", date2);
    formData.append("class_time_id", cl.id);
    formData.append("users_id", user.id);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/book`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        if (res.status === 200) {
          dispatch(addBooking(res.data.book));
          setSuccess(true);
          setMessage(res.data.message);
        }
      })
      .catch((e) => {
        // handle error
        setError(true);
        setMessage(e.response.data.message);
      });
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  useEffect(() => {
    if (weekStart) {
      var datearray = weekStart.split("/");
      var newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
      let date = new Date(newdate);
      setDate(formatDate(addDays(date, number)));
    }
  }, [weekStart]);
  const addDays = (date, days) => {
    var date2 = new Date(date);
    date2.setDate(date2.getDate() + number);
    return date2;
  };
  return (
    <>
      <button
        className="grid grid-cols-12 w-full mt-2  overflow-x-hidden bg-gray-500 px-12 h-12 items-center"
        onClick={() => setOpen(!open)}
      >
        <div className="col-span-11 text-start px-12 font-bold text-lg text-white">
          {day}
        </div>
        <div className="col-span-1">
          <AiOutlineArrowDown
            className={`text-2xl text-white border-2 rounded-xl cursor-pointer ${
              open ? "rotate-180" : "rotate-0"
            } transition-all duration-500 ease-in-out`}
          />
        </div>
      </button>
      {classes.length > 0 ? (
        classes.map((cl) => {
          return (
            <div
              className={`grid grid-cols-3 px-4 bg-gray-300 items-center text-center ${
                open ? "max-h-0 py-12 border-2" : "max-h-0 py-0"
              }   transition-all duration-700 ease-in-out`}
            >
              <h3
                className={`${
                  open ? "opacity-1" : "opacity-0"
                } transition-all duration-500 ease-in-out`}
              >
                {cl.start_time}-{cl.end_time}
              </h3>
              <h3
                className={`${
                  open ? "opacity-1" : "opacity-0"
                } transition-all duration-500 ease-in-out`}
              >
                {cl.classes[0].name}
              </h3>
              <button
                className={`${
                  open ? "opacity-1" : "opacity-0"
                } transition-all duration-500 ease-in-out rounded font-bold cursor-pointer text-green-600`}
                onClick={() => submitBook(cl)}
              >
                Join
              </button>
            </div>
          );
        })
      ) : (
        <div
          className={`grid grid-cols-3 px-4 bg-gray-300 items-center text-center ${
            open ? "max-h-40 py-3 border-2" : "max-h-0 py-0"
          }   transition-all duration-700 ease-in-out`}
        >
          <h3
            className={`${
              open ? "opacity-1" : "opacity-0"
            } transition-all duration-500 ease-in-out`}
          >
            No available classes
          </h3>
        </div>
      )}
    </>
  );
}
