import React, { useEffect } from "react";
import { FiArrowUp } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Contact from "../components/contact";
import Courses from "../components/courses";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Location from "../components/location";
import Navbar from "../components/navbar";
import Snackbar from "../components/Snackbar/Snackbar";
import "../css/global.css";
export default function Home() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (user.token) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <div className="bg-gray-200  z-0 text-gray-700 h-screen snap-y snap-mandatory overflow-y-scroll overflow-x-hidden scrollbar scrollbar-track-gray-400/20 scrollbar-thumb-[#f72d0a]/80">
      <Snackbar />
      <Navbar />
      <section id="hero" className="snap-start">
        <Hero />
      </section>
      <section id="classes" className="snap-center">
        <Courses />
      </section>
      <section id="contact" className="snap-start">
        <Contact />
      </section>
      <section id="location" className="snap-start">
        <Location />
      </section>

      <section id="#footer" className="snap-end">
        <Footer />
      </section>
    </div>
  );
}
