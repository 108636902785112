import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../axiosInstance";
import { addBooking } from "../../redux/bookingsSlice";
import Snackbar from "../Snackbar/Snackbar";

export default function SelectForm({}) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const snackbarRef = useRef(null);
  const snackbarRef2 = useRef(null);
  const classTimes = useSelector((state) => state.classTimes);
  const user = useSelector((state) => state.user);
  const [startDate, setDate] = useState(new Date());
  const [day, setDay] = useState();
  const [lessons, setLessons] = useState(null);
  const [active, setActive] = useState(null);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const hours = useSelector((state) => state.hour);
  useEffect(() => {
    setDay(days[startDate.getDay()]);
    const newLessons = classTimes.filter((cl) => cl.days === day);
    newLessons.sort(function (a, b) {
      return (
        new Date("1970/01/01 " + a.start_time) -
        new Date("1970/01/01 " + b.start_time)
      );
    });
    setLessons((lessons) => [...newLessons]);
  }, [startDate, classTimes, day]);
  useEffect(() => {
    if (lessons && lessons[0]) {
      setActive(lessons[0].id);
    }
  }, [lessons]);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const submitBook = () => {
    const formData = new FormData();
    formData.append("date", formatDate(startDate));
    formData.append("class_time_id", active);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/book`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(addBooking(res.data.book));
        setMessage(res.data.message);
        snackbarRef.current.show();
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        snackbarRef2.current.show();
      });
  };
  return (
    <div className="text-center text-white w-full pt-12 mt-auto lg:mb-12 mb-32  py-12 bg-gray-200">
      <h5 className="text-red-600 p-4 mx-auto text-2xl font-semibold">
        Book your Class
      </h5>
      <div className=" flex lg:flex-row flex-col justify-evenly  mx-auto md:mt-8 mt-1 items-center align-middle 2xl:px-96 px-16 lg:space-y-0 space-y-1 lg:w-full w-screen">
        <div>
          <ReactDatePicker
            onChange={(date) => setDate(date)}
            selected={startDate}
            withPortal
            className="text-black p-2 cursor-pointer rounded inline-block lg:w-full w-full"
            placeholderText="Date"
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
          />
        </div>
        <div>
          <div className="">
            <select
              className="p-2 rounded text-black md:mt-0 mt-4 md:mb-0 mb-4 lg:w-full w-full bg-white"
              placeholder="Hour"
              value={active}
              onChange={(e) => setActive(e.target.value)}
            >
              {lessons && lessons.length > 0 ? (
                hours.map((h) => {
                  return lessons.map((l) => {
                    if (
                      h.start_time === l.start_time &&
                      h.end_time === l.end_time
                    ) {
                      return (
                        <option value={l.id}>
                          {l.start_time}-{l.end_time} Lesson:{" "}
                          {l.classes[0].name}
                        </option>
                      );
                    }
                  });
                })
              ) : (
                <option>No Lessons Available</option>
              )}
            </select>
          </div>
        </div>
        <div>
          <button
            className="bg-white text-black rounded p-2 w-48 hover:bg-red-500 hover:text-white transition-all duration-250 ease-in"
            onClick={submitBook}
          >
            Book
          </button>
        </div>
      </div>
      <Snackbar
        type="success"
        message="Your book has been accepted!"
        ref={snackbarRef}
      />
      <Snackbar type="error" message={message} ref={snackbarRef2} />
    </div>
  );
}
