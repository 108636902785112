import React, { useState } from "react";
import "../css/courses.css";
import { GrPrevious, GrNext } from "react-icons/gr";
import { motion } from "framer-motion";
export default function Courses() {
  const [show, setShow] = useState(false);
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%", height: 0 },
  };
  const variants2 = {
    open: { opacity: 0, x: "-100%", height: 0 },
    closed: { opacity: 1, x: 0 },
  };
  console.log(show);
  return (
    <div className="h-screen flex relative flex-col text-center md:text-left  max-w-7xl px-10 justify-evenly mx-auto items-center">
      <motion.div
        initial={{ x: -200, opacity: 0 }}
        transition={{
          duration: 1.2,
        }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="text-center title md:w-1/2 w-4/5 mx-auto absolute md:top-24 top-20 uppercase tracking-[20px] text-gray-500 text-2xl font-semibold"
      >
        Courses
      </motion.div>
      <div className="grid md:grid-cols-8 grid-cols-2 items-center content-center text-center md:mt-16 mt-12 px-2">
        <div
          className="md:block hidden mx-auto cursor-pointer text-red-700"
          onClick={() => setShow(!show)}
        >
          <GrPrevious />
        </div>
        <div className="col-span-6">
          <motion.div
            className={`md:grid-cols-3 grid-cols-2 w-full gap-12 grid transition ease duration-1000 `}
            animate={show ? "open" : "closed"}
            variants={variants}
          >
            <div>
              <h3 className="course-title">Level 1</h3>
              <p>Προχωρημένο στάδιο σύνθετων ασκήσεων υψηλής έντασης</p>
            </div>
            <div>
              <h3 className="course-title">Basic</h3>
              <p>Βασικό στάδιο ασκήσεων υψηλής έντασης</p>
            </div>
            <div>
              <h3 className="course-title">Gymnastics</h3>
              <p>
                Εξειδικευμένο πρόγραμμα ενόργανης γυμναστικής βασισμένο σε
                ασκήσεις που χρησιμοποιούμε
              </p>
            </div>
            <div>
              <h3 className="course-title">Weightlifting</h3>
              <p>
                Εξειδικευμένο πρόγραμμα ολυμπιακής άρσης βαρών βασισμένο σε
                ασκήσεις που χρησιμοποιούμε
              </p>
            </div>
            <div>
              <h3 className="course-title">Stretching</h3>
              <p>
                Πλήρες πρόγραμμα διατάσεων και αποκατάστασης για όλο το σώμα
              </p>
            </div>
            <div>
              <h2 className="course-title">HEALTH</h2>
              <h4>
                The first stage of contacting and learning basic exercises of
                strength and endurance
              </h4>
            </div>
          </motion.div>
          <motion.div
            className={`md:grid-cols-3 grid-cols-2 w-full gap-12 grid transition ease duration-1000 
            `}
            animate={show ? "open" : "closed"}
            variants={variants2}
          >
            <div>
              <h3 className="course-title">Health</h3>
              <p>
                The first stage of contacting and learning basic exercises of
                strength and endurance
              </p>
            </div>
            <div>
              <h3 className="course-title">Level 1</h3>
              <p>Advanced stage of complex high intensive exercises</p>
            </div>
            <div>
              <h3 className="course-title">Basic</h3>
              <p>Basic stage of high intensity exercises</p>
            </div>
            <div>
              <h3 className="course-title">Gymnastics</h3>
              <p>Specialized gymnastics program based on exercises we use</p>
            </div>
            <div>
              <h3 className="course-title">Weightlifting</h3>
              <p>
                Specialized Olympic weightlifting program based on exercises we
                use
              </p>
            </div>
            <div>
              <h3 className="course-title">Stretching</h3>
              <p>Full body stretching and restoration program</p>
            </div>
          </motion.div>
        </div>
        <div
          className="mx-auto cursor-pointer md:block hidden"
          onClick={() => setShow(!show)}
        >
          <GrNext />
        </div>
      </div>
    </div>
  );
}
