import React, { useState, useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import BackgroundCircles from "./BackgroundCircles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { updatePhoto } from "../redux/userSlice";
import Snackbar from "./Snackbar/Snackbar";
import { addBooking } from "../redux/bookingsSlice";
import SelectForm from "./select/selectForm";

export default function HeroLogged({ logged }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classTimes = useSelector((state) => state.classTimes);
  const books = useSelector((state) => state.bookings);
  const [hover, setHover] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [lesson, setLesson] = useState(0);
  const [day, setDateToday] = useState("");
  const snackbarRef = useRef(null);
  const snackbarRef2 = useRef(null);
  const [message, setMessage] = useState("");
  const [todayLesson, setTodayLesson] = useState([]);
  const [text, count] = useTypewriter({
    words: [
      "Welcome to Barbox",
      "Strength & Conditioning Club",
      "Cross Training Gym At Rethymno",
    ],
    loop: true,
    delaySpeed: 2000,
  });
  const [loggedText, count2] = useTypewriter({
    words: [`Subscription: ${user.sub} days`],
    loop: true,
    delaySpeed: 2000,
  });
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  const dateChange = (date) => {
    setStartDate(date);
    const dayNumber = date.getDay();
    let dayString;
    switch (dayNumber) {
      case 0:
        dayString = "Sunday";
        break;
      case 1:
        dayString = "Monday";
        break;
      case 2:
        dayString = "Tuesday";
        break;
      case 3:
        dayString = "Wednesday";
        break;
      case 4:
        dayString = "Thursday";
        break;
      case 5:
        dayString = "Friday";
        break;
      case 6:
        dayString = "Saturday";
        break;
      default:
        dayString = "";
    }
    setDateToday(dayString);
    setTodayLesson(
      ...classTimes.filter((classTime) => classTime.days === dayString)
    );
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const submitBook = () => {
    const formData = new FormData();
    formData.append("date", formatDate(startDate));
    formData.append("class_time_id", lesson);
    formData.append("users_id", user.id);
    const response = axios
      .post("https://api.barbox.gr/api/web/book", formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        dispatch(addBooking(res.data.book));
        snackbarRef.current.show();
      })
      .catch((error) => {
        setMessage(error.response.data.message.original.message);
        snackbarRef2.current.show();
      });
  };
  useEffect(() => {
    dateChange(new Date());
  }, []);
  const updatePhoto2 = (e) => {
    const formData = new FormData();
    console.log(e);
    formData.append("avatar", e.target.files[0]);
    axios
      .post(
        "https://api.barbox.gr/api/web/user/settings/upload-avatar",
        formData,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((res) => dispatch(updatePhoto(res.data.user.avatar)))
      .catch((err) => console.log(err));
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center text-center overflow-hidden">
      <BackgroundCircles />

      <div className="relative cursor-pointer ">
        <img
          src={user.avatar == "default.jpg" ? "/Avatar.png" : user.avatar}
          className={`block rounded-full h-32 w-32 mx-auto object-cover ${
            hover ? "bg-gray-700/90" : "bg-transparent"
          } transition-all duration-300 z-10`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
        <div class="image-upload" for="file-input">
          <form>
            <label
              for="file-input"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <AiOutlinePlusCircle
                className={`absolute ${
                  hover ? "opacity-1" : "opacity-0"
                } transition-all duration-200 text-gray-100 z-0 cursor-pointer`}
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: -1,
                }}
                size={32}
              />
            </label>
            <input
              id="file-input"
              type="file"
              className="hidden"
              onChange={(e) => updatePhoto2(e)}
            />
          </form>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="z-10">
          <h2 className="text-sm uppercase text-gray-500 pb-2 tracking-[15px]">
            Cross Box
          </h2>
          <h1 className="text-2xl lg:text-6xl font-semibold px-10">
            <span className="mt-3">{logged ? loggedText : text}</span>
            <Cursor cursorColor="#f72d0a" />
          </h1>
          <div className="pt-5">
            <a href="#classes">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Classes
              </button>
            </a>
            <a href="#statistics">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Statistics
              </button>
            </a>
            <a href="#contact">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Contact
              </button>
            </a>
            <a href="#location">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Location
              </button>
            </a>
          </div>
        </div>
      </div>

      <SelectForm />
    </div>
  );
}
