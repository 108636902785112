import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
  name: "post",
  initialState: [],
  reducers: {
    getAllPosts: (state, action) => {
      return action.payload;
    },

    logoutPosts: (state, action) => {
      return [];
    },
  },
});

export const { getAllPosts, logoutPosts } = postSlice.actions;
export default postSlice.reducer;
