import React from "react";
import "../css/courses.css";
import { motion } from "framer-motion";
import Footer from "./footer";
export default function Location() {
  return (
    <div className="h-screen flex relative flex-col text-center md:text-left  max-w-7xl md:px-10 justify-center mx-auto items-center">
      <motion.div
        initial={{ x: -200, opacity: 0 }}
        transition={{
          duration: 1.2,
        }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="text-center title md:w-1/2 w-4/5 mx-auto absolute  md:top-12 top-20 uppercase tracking-[20px] text-gray-500 text-2xl font-semibold py-4"
      >
        Location
      </motion.div>
      <iframe
        className="w-full  md:px-16 px-0 rounded lg:mb-12 mt-24"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3253.542739326663!2d24.48998151539645!3d35.3669926802688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149b756a15907679%3A0xdd4831d488f1dad8!2sBarBox%20Strength%20%26%20Conditioning%20Club!5e0!3m2!1sen!2sgr!4v1657964950913!5m2!1sen!2sgr"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
