import React, { useEffect, useRef, useState } from "react";
import CourseTable from "../components/courseTable";
import Navbar from "../components/navbar";
import Contact from "../components/contact";
import Footer from "../components/footer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAllclassTimes } from "../redux/classTimeSlice";
import { FiArrowUp } from "react-icons/fi";
import { getAllBookings } from "../redux/bookingsSlice";
import Modal from "../components/bodyModal/modal";
import { getBody } from "../redux/bodySlice";
import Graph from "../components/graphs/graph";
import { useNavigate } from "react-router-dom";
import HeroLogged from "../components/herologged";
import Snackbar from "../components/Snackbar/Snackbar";
import { addPersonalInfo } from "../redux/userSlice";
import { motion } from "framer-motion";
import { getAllWods } from "../redux/wodSlice";
import { getAllPosts } from "../redux/postSlice";
import WodPanel from "../components/wodPanel";
import { AiOutlineClose } from "react-icons/ai";
export default function User() {
  const user = useSelector((state) => state.user);
  const body = useSelector((state) => state.body);
  const wods = useSelector((state) => state.wod);
  const notifications = useSelector((state) => state.notifications);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [close, setClose] = useState(false);
  const snackbarRef = useRef();

  const navigate = useNavigate();
  useEffect(() => {
    if (body.length > 0) {
      setOpen((open) => false);
    } else {
      setOpen((open) => true);
    }
  }, [body]);
  useEffect(() => {
    if (success) {
      snackbarRef.current.show();
    }
  }, [success]);
  useEffect(() => {
    if (notifications.length > 0) {
      setClose(true);
    }
  }, [notifications]);
  return (
    <div className="bg-gray-200  z-0 text-gray-700 h-screen 2xl:snap-y xl:snap-mandatory overflow-y-scroll overflow-x-hidden scrollbar scrollbar-track-gray-400/20 scrollbar-thumb-[#f72d0a]/80">
      <Modal
        isOpen={open}
        position={[-1.2, 0, 0]}
        setIsOpen={setOpen}
        setSuccess={setSuccess}
      />
      {close ? (
        <div className="w-screen flex justify-center">
          <div className="absolute top-4 px-8 py-4 rounded-lg justify-center flex bg-red-500  h-[] z-30 max-w-[400px]">
            <div className="flex justify-center">
              <div className="flex flex-col relative">
                <button
                  className="absolute top-0 right-0 text-gray-50"
                  onClick={() => setClose(!close)}
                >
                  <AiOutlineClose size={18} />
                </button>
                <div className="flex justify-center text-gray-50">
                  {notifications[0].title}
                </div>
                <div className="text-gray-50">{notifications[0].content}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <Navbar logged={true} />
      <section id="hero" className="xl:snap-start">
        <HeroLogged logged={true} />
      </section>
      <section id="classes" className="xl:snap-center">
        <CourseTable />
      </section>
      {wods.length > 0 ? (
        <section id="wod" className="snap-start">
          <WodPanel />
        </section>
      ) : (
        ""
      )}

      {body.length > 0 ? (
        <section id="statistics" className="xl:snap-center">
          <Graph />
        </section>
      ) : (
        ""
      )}

      <section id="contact" className="xl:snap-start">
        <Contact />
      </section>

      <Snackbar
        type="success"
        message="Body Data submitted!"
        ref={snackbarRef}
      />
      <section id="#footer" className="xl:snap-end mt-32">
        <Footer />
      </section>
    </div>
  );
}
