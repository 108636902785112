import React from "react";
import Navbar from "../components/navbar";
import SettingsSession from "../components/settingsSession";

export default function Settings() {
  return (
    <div className="bg-gray-200  z-0 text-gray-700 h-screen 2xl:snap-y xl:snap-mandatory overflow-y-scroll overflow-x-hidden scrollbar scrollbar-track-gray-400/20 scrollbar-thumb-[#f72d0a]/80">
      <Navbar logged={true} />
      <SettingsSession />
    </div>
  );
}
