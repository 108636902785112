import React, { useState, useEffect } from "react";
import { addDays, format, getDate, parseISO, startOfWeek } from "date-fns";
import { GrPrevious, GrNext } from "react-icons/gr";
import BodyFat from "./bodyFat";
import Days from "./days";
import Classes from "./classes";
export default function Graph() {
  const [show, setShow] = useState(false);
  const [courseClick, setCourseClick] = useState({
    title: "",
    color: "",
    description: "",
    index: 0,
    time: "",
  });
  const [weekStart, setWeekStart] = useState("");
  const [weekEnd, setWeekEnd] = useState("");

  const handleChange = (course, index, time) => {
    setShow(true);
    setCourseClick({
      ...courseClick,
      title: course.title,
      color: course.color,
      description: course.description,
      time: time,
      index: index,
    });
  };
  useEffect(() => {
    getWeekDate(Date.now());
  }, []);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const addDays = (date, daysAdd) => {
    var datearray = date.split("/");
    var newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
    var d = new Date(newdate);
    d.setDate(d.getDate() + daysAdd);
    return d;
  };
  const getWeekDate = (d) => {
    var curr = new Date(d); // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));
    if (lastday > new Date()) {
      setWeekStart(formatDate(firstday));
      setWeekEnd(formatDate(lastday));
    }
  };

  return (
    <div className="2xl:h-screen h-full flex  flex-col space-y-8   text-center overflow-hidden">
      <h2 className="title md:w-1/2 w-full md:px-0 px-12 py-12 mx-auto mt-8 text-3xl text-bold">
        Statistics
      </h2>
      <div className="w-full mt-4 mx-auto text-center flex items-center justify-center">
        <BodyFat />
      </div>
      <div className="flex lg:flex-row flex-col justify-center items-center h-1/2">
        <div className="">
          <Days />
        </div>
        <div className="w-1/2 h-full ">
          <Classes />
        </div>
      </div>
    </div>
  );
}
