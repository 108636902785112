import { createSlice } from '@reduxjs/toolkit';

export const classTimesSlice = createSlice({
	name: 'classTimes',
	initialState: [],
	reducers: {
		getAllclassTimes: (state, action) => {
			return action.payload.classTimes;
		},

		logoutClassTime: (state, action) => {
			return [];
		},
	},
});

export const { getAllclassTimes, logoutClassTime } = classTimesSlice.actions;
export default classTimesSlice.reducer;
