import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePhoto } from "../../redux/userSlice";
import { motion } from "framer-motion";
import instance from "../../axiosInstance";
export default function PersonalInfo() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [fname, setFname] = useState(user.fname);
  const [lname, setLname] = useState("");
  const [add, setAdd] = useState("");
  const [city, setCity] = useState("");
  const [st, setSt] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (user.personalInfo) {
      setFname((fname) => user.personalInfo.first_name);
      setLname((lname) => user.personalInfo.last_name);
      setAdd((add) => user.personalInfo.address);
      setCity((city) => user.personalInfo.city);
      setSt((st) => user.personalInfo.state);
      setZip((zip) => user.personalInfo.zip);
      setCountry((country) => user.personalInfo.country);
      setPhone((phone) => user.personalInfo.phone);
    }
  }, []);

  const submitForm = () => {
    const formData = new FormData();
    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("address", add);
    formData.append("city", city);
    formData.append("state", st);
    formData.append("zip", zip);
    formData.append("country", country);
    formData.append("phone", phone);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/update-user-info`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        console.log(res);
      })
      .catch((e) => {
        // handle error
      });
  };
  return (
    <motion.div
      initial={{ x: -200, opacity: 0 }}
      transition={{
        duration: 1.2,
      }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      className="flex flex-col justify-start items-center mt-16 lg:px-64 px-12"
    >
      <div className="border-b-2 w-full"></div>
      <div className="flex flex-col w-full px-12 mt-8 space-y-32">
        <div className="flex flex-row justify-center items-center flex-wrap gap-4">
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">First Name</h4>
            <input
              type="text"
              value={fname}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setFname(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Last Name</h4>
            <input
              type="text"
              value={lname}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setLname(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Address</h4>
            <input
              type="text"
              value={add}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setAdd(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">City</h4>
            <input
              type="text"
              value={city}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">State</h4>
            <input
              type="text"
              value={st}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setSt(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">ZIP</h4>
            <input
              type="text"
              value={zip}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setZip(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Country</h4>
            <input
              type="text"
              value={country}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Phone</h4>
            <input
              type="text"
              value={phone}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row">
          <button
            className="border-2 rounded-xl p-4 border-red-600 text-red-600 hover:bg-red-600 hover:text-gray-200 transition-all duration-150 block mx-auto"
            onClick={() => submitForm()}
          >
            Update Profile
          </button>
        </div>
      </div>
    </motion.div>
  );
}
