import React, { useEffect, useState } from "react";
import "../../css/loginModal.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addBooking } from "../../redux/bookingsSlice";
import instance from "../../axiosInstance";
export default function BookModal({
  isOpen,
  setIsOpen,
  course,
  date,
  setSuccess,
  setMessage,
  setError,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const submitBook = () => {
    const formData = new FormData();
    formData.append("date", date);
    formData.append("class_time_id", course.id);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/book`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        setIsOpen(false);
        setMessage(res.data.message);
        dispatch(addBooking(res.data.book));
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        setError(true);
        setIsOpen(false);
      });
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto z-30"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4 w-1/2 ">
        <span
          className="flex justify-end text-2xl cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          &times;
        </span>
        <h3 className="text-gray-500 text-3xl mt-4 text-center">
          {course.classes[0].name}
        </h3>

        <p className="text-center">{course.classes[0].description}</p>
        <p>
          Ημερομηνία Μαθήματος:{date}
          <hr></hr>Ώρα:{course.start_time}
          {"-"}
          {course.end_time}
        </p>
        <button
          className="rounded px-4 py-2 mx-auto block bg-red-400 mt-4 text-gray-100"
          onClick={() => submitBook()}
        >
          Join
        </button>
      </div>
    </div>
  );
}
