import React, { useState, useMemo } from "react";
import AllBooks from "./allBooks";
import PreviousBooks from "./previousBooks";
import NextBooks from "./nextBooks";
import { useSelector } from "react-redux";
export default function HistoryTable() {
  const [active, setActive] = useState(1);

  const pageChange = (index) => {
    switch (index) {
      case 1:
        return <NextBooks />;
      case 2:
        return <PreviousBooks />;
      case 3:
        return <AllBooks />;
    }
  };
  return (
    <div>
      <div className="flex flex-row justify-evenly items-center">
        <div>
          <h3
            className={`${
              active === 1
                ? "text-red-600 border-2 border-red-600 rounded-md p-2 font-semibold transition-all duration-300 cursor-pointer"
                : "text-gray-400 cursor-pointer transition-all duration-300 p-2"
            }`}
            onClick={() => setActive(1)}
          >
            Upcoming Books
          </h3>
        </div>
        <div>
          <h3
            className={`${
              active === 2
                ? "text-red-600 border-2 border-red-600 rounded-md p-2 font-semibold transition-all duration-300 cursor-pointer"
                : "text-gray-400 cursor-pointer transition-all duration-300 p-2"
            }`}
            onClick={() => setActive(2)}
          >
            Previous Books
          </h3>
        </div>
        <div>
          <h3
            className={`${
              active === 3
                ? "text-red-600 border-2 border-red-600 rounded-md p-2 font-semibold transition-all duration-300 cursor-pointer"
                : "text-gray-400 cursor-pointer p-2 transition-all duration-300"
            }`}
            onClick={() => setActive(3)}
          >
            All Books
          </h3>
        </div>
      </div>
      {pageChange(active)}
    </div>
  );
}
