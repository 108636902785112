import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Model } from "../Male";
import { useForm } from "react-hook-form";
import { BiFemaleSign, BiMaleSign } from "react-icons/bi";
import Slider from "react-input-slider";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addBody } from "../../redux/bodySlice";
import Snackbar from "../Snackbar/Snackbar";
import instance from "../../axiosInstance";
export default function Body() {
  const body = useSelector((state) => state.body);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [gender, setGender] = useState("male");
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [waist, setWaist] = useState(0);
  const [chest, setChest] = useState(0);
  const [hips, setHips] = useState(0);
  const [arms, setArms] = useState(0);
  const [neck, setNeck] = useState(0);
  const [thighs, setThighs] = useState(0);
  const [bodyFat, setBodyFat] = useState(10);
  const [message, setMessage] = useState("");
  const snackbarRef = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (body.length) {
      setHeight((height) => body[body.length - 1].height);
      setWeight((weight) => body[body.length - 1].weight);
      setWaist((waist) => body[body.length - 1].waist);
      setChest((chest) => body[body.length - 1].chest);
      setHips((hips) => body[body.length - 1].hips);
      setArms((arms) => body[body.length - 1].arms);
      setNeck((neck) => body[body.length - 1].neck);
      setBodyFat((bodyFat) => body[body.length - 1].body_fat);
      setThighs((thighs) => body[body.length - 1].thighs);
    }
  }, []);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const onSubmit = () => {
    const formData = new FormData();
    formData.append("gender", gender);
    formData.append("height", height);
    formData.append("weight", weight);
    formData.append("waist", waist);
    formData.append("chest", chest);
    formData.append("hips", hips);
    formData.append("arms", arms);
    formData.append("neck", neck);
    formData.append("thighs", thighs);
    formData.append("body_fat", 0);
    formData.append("date", formatDate(new Date()));
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/add-body-info`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(addBody(res.data.user_body));
        snackbarRef.current.show();
      })
      .catch((e) => {
        // handle error
      });
  };
  return (
    <motion.div
      initial={{ x: -200, opacity: 0 }}
      transition={{
        duration: 1.2,
      }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      className="flex flex-col justify-start items-center mt-16 lg:px-64 px-12"
    >
      <div className="flex flex-col w-full px-12 mt-8 space-y-32">
        <div className="flex flex-row justify-center gap-12 flex-wrap">
          <div className="">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-row justify-center gap-12 flex-wrap"
            >
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Weight</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={weight}
                    onChange={(x) => setWeight(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Height</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={height}
                    onChange={(x) => setHeight(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Waist</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={waist}
                    onChange={(x) => setWaist(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Chest</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={chest}
                    onChange={(x) => setChest(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Hips</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={hips}
                    onChange={(x) => setHips(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Arms</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={arms}
                    onChange={(x) => setArms(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Neck</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={neck}
                    onChange={(x) => setNeck(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
              <div>
                <label>
                  <h5 className="text-gray-700 text-xl">Thighs</h5>
                </label>
                <div className="flex-col space-x-4">
                  <input
                    type="number"
                    value={thighs}
                    onChange={(x) => setThighs(x.target.value)}
                    className="rounded-md px-4 mt-2"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-row">
          <button
            className="border-2 rounded-xl p-4 border-red-600 text-red-600 hover:bg-red-600 hover:text-gray-200 transition-all duration-150 block mx-auto"
            onClick={onSubmit}
          >
            Update Profile
          </button>
        </div>
        <Snackbar
          type="success"
          message="Body Data submitted!"
          ref={snackbarRef}
        />
      </div>
    </motion.div>
  );
}
