import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import classReducer from "./classTimeSlice";
import bookingsReducer from "./bookingsSlice";
import bodyReducer from "./bodySlice";
import wodReducer from "./wodSlice";
import postReducer from "./postSlice";
import hoursReducer from "./hoursSlice";
import notificationsReducer from './notificationsSlice'
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
const persistConfig = {
  key: "root2",
  storage,
};
const combReduces = combineReducers({
  user: userReducer,
  classTimes: classReducer,
  bookings: bookingsReducer,
  body: bodyReducer,
  wod: wodReducer,
  post: postReducer,
  hour: hoursReducer,
  notifications: notificationsReducer,
});
const persistedReducer = persistReducer(persistConfig, combReduces);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
