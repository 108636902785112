import React from "react";
import { FiInstagram, FiFacebook } from "react-icons/fi";
export default function Footer() {
  return (
    <div className="grid grid-rows-12 bg-gray-700 w-screen ">
      <div className="h-full p-4  grid md:grid-cols-5 row-span-10">
        <div></div>
        <div className="mx-auto mt-8">
          <img
            src="/logo.png"
            width={64}
            height={64}
            className="mx-auto"
            style={{
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
          <h1 className="text-center text-2xl mt-2 text-gray-200">
            BarBox Gym
          </h1>
          <h4 className="text-gray-400">
            That voice in your head that says you can't do this is a liar.
          </h4>
        </div>
        <div className="mx-auto mt-8">
          <h2 className="text-gray-200">Contact Info</h2>
          <h4 className="text-gray-300">
            Phone 1:
            <a href="tel:6979350530" className="text-red-400">
              {" "}
              6979350530
            </a>
          </h4>
          <h4 className="text-gray-300">
            Phone 2:
            <a href="tel:6976742737" className="text-red-400">
              {" "}
              6976742737
            </a>
          </h4>
          <h4 className="text-gray-300">
            Phone 3:
            <a href="tel:6972598270" className="text-red-400">
              {" "}
              6972598270
            </a>
          </h4>
        </div>
        <div className="mx-auto mt-8">
          <h2 className="text-gray-200">Social Media</h2>
          <FiInstagram className="text-red-400 mt-4 inline-block" size={25} />
          <FiFacebook
            className="text-red-400 mt-4 inline-block ml-4"
            size={25}
          />
        </div>
        <div></div>
      </div>
      <div
        className="border-t-1 border-gray-100"
        style={{ borderTop: "1px solid white" }}
      >
        <h6 className="text-center text-gray-300 text-sm">
          All rights reserved by © BarBox.gr
        </h6>
      </div>
    </div>
  );
}
