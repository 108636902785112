import React, { useRef, useState } from "react";
import "../css/loginModal.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux/es/exports";
import axios from "axios";
import Snackbar from "./Snackbar/Snackbar";
import { login } from "../redux/userSlice";
import { AiOutlineArrowLeft } from "react-icons/ai";
import instance from "../axiosInstance";
export default function ForgotPassword({
  isOpen,
  setIsOpen,
  setlogOpen,
  setSuccess,
  setMessage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const snackbarRef2 = useRef(null);
  const snackbarRef = useRef(null);
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("email", data.email);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/forgot-password`,
      method: "POST",
      data: formdata,
    })
      .then((res) => {
        // handle success
        if (res.status === 200) {
          setlogOpen(false);
          setIsOpen(false);
          setMessage(res.data.message);
          setSuccess(true);
        }
      })
      .catch((e) => {
        // handle error
        setError(e.response.data.message);
        snackbarRef2.current.show();
      });
  };
  const handleClose = () => {
    clearErrors("name");
    clearErrors("email");
    clearErrors("password");
    clearErrors("conf_password");
    setIsOpen(false);
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto  h-screen z-30"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4 md:w-1/2 w-screen">
        <span
          className="text-2xl flex justify-end cursor-pointer font-bold"
          onClick={() => handleClose()}
        >
          <AiOutlineArrowLeft />
        </span>
        <h3 className="text-gray-500 text-3xl mt-4 text-center">
          Reset Password
        </h3>
        <form
          className=" w-64 block mx-auto py-2 mt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label>Email Address</label>
          {errors.email && (
            <p className="text-red-600">{errors.email.message}</p>
          )}
          <input
            type="text"
            {...register("email", {
              required: "Email is required",
            })}
            placeholder="Email address"
            style={{
              color: "#5f6061",
              border: errors.email ? "1px solid red" : "1px solid gray",
            }}
            className="w-[250px] my-2 p-2 rounded"
          />

          <input
            type="submit"
            value="Reset"
            className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white"
          />
        </form>
        <Snackbar type="error" message={error} ref={snackbarRef2} />
      </div>
    </div>
  );
}
