import React, { forwardRef, useState, useImperativeHandle } from "react";
import "./Snackbar.css";
import { TiTickOutline } from "react-icons/ti";
import { MdErrorOutline } from "react-icons/md";
const Snackbar = forwardRef((props, ref) => {
  const [showSnackbar, setShowSnackbar] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setShowSnackbar(true);
    
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    },
  }));
  return (
    <div
      className="snackbar p-4 z-30"
      id={showSnackbar ? "show" : "hide"}
      style={{
        color: "white",
        backgroundColor: props.type === "success" ? "#00f593" : "#fa4b51",
      }}
    >
      <div className="symbol">
        {props.type === "success" ? (
          <TiTickOutline size={45} color="white" />
        ) : (
          <MdErrorOutline size={45} color="white" />
        )}
      </div>
      <div className="message text-base tracking-widest">{props.message}</div>
    </div>
  );
});
export default Snackbar;
