import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Snackbar from "../Snackbar/Snackbar";
import Cl from "./cl";
import PhoneSchedule from "./phoneSchedule";
import Square from "./square";
export default function Schedule({
  weekStart,
  weekEnd,
  setMessage,
  setSuccess,
  setError,
}) {
  const classes = useSelector((state) => state.classes);
  const class_times = useSelector((state) => state.classTimes);
  const data = useSelector((state) => state.hour);

  const week = [
    { id: 0, day: "Monday" },
    { id: 1, day: "Tuesday" },
    { id: 2, day: "Wednesday" },
    { id: 3, day: "Thursday" },
    { id: 4, day: "Friday" },
    { id: 5, day: "Saturday" },
    { id: 6, day: "Sunday" },
  ];

  return (
    <>
      <div className="md:hidden block h-[500px]">
        <PhoneSchedule
          data={data}
          week={week}
          classTimes={class_times}
          weekStart={weekStart}
          weekEnd={weekEnd}
          setSuccess={setSuccess}
          setError={setError}
          setMessage={setMessage}
        />
      </div>
      <div className="drop mt-8 mb-4 lg:mx-32 mx-0 md:block hidden">
        <table class="w-full mx-auto text-sm text-left text-gray-500 px-32">
          <thead className="text-xs text-gray-50 uppercase bg-gray-700 text-center">
            <tr>
              <th scope="col" class="py-3 px-6"></th>
              <th scope="col" class="py-3 px-6">
                Monday
              </th>
              <th scope="col" class="py-3 px-6">
                Tuesday
              </th>
              <th scope="col" class="py-3 px-6">
                Wednesday
              </th>
              <th scope="col" class="py-3 px-6">
                Thursday
              </th>
              <th scope="col" class="py-3 px-6">
                Friday
              </th>
              <th scope="col" class="py-3 px-6">
                Saturday
              </th>
              <th scope="col" class="py-3 px-6">
                Sunday
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              ? data.map((time) => {
                  return (
                    <tr>
                      <th className="text-center">
                        {time.start_time}-{time.end_time}
                      </th>
                      {week.map((cl, index) => {
                        let flag = 0;
                        return (
                          <th classname="cursor-pointer">
                            {class_times.map((course) => {
                              switch (index) {
                                case 0:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                        number={index}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                case 1:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        number={index}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                case 2:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        number={index}
                                        name={cl.name}
                                        course={course}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                case 3:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        number={index}
                                        course={course}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                case 4:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        number={index}
                                        course={course}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                case 5:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        number={index}
                                        course={course}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                case 6:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        number={index}
                                        course={course}
                                        weekStart={weekStart}
                                        weekEnd={weekEnd}
                                        setMessage={setMessage}
                                        setSuccess={setSuccess}
                                        setError={setError}
                                      />
                                    );
                                  }
                                  break;
                                default:
                                  return "";
                              }
                            })}
                            {flag === 0 ? (
                              <Square
                                start={time.start_time}
                                end={time.end_time}
                                days={cl.day}
                                name={cl.name}
                                setMessage={setMessage}
                                setSuccess={setSuccess}
                                setError={setError}
                              />
                            ) : (
                              ""
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </>
  );
}
