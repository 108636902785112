import React, { useRef } from "react";
import "../css/loginModal.css";
import { useForm } from "react-hook-form";

import axios from "axios";
import Snackbar from "./Snackbar/Snackbar";
import instance from "../axiosInstance";
export default function RegisterModal({ isOpen, setIsOpen, snackRef }) {
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const snackbarRef = useRef(null);
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    formdata.append("password_confirmation", data.conf_password);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/register`,
      method: "POST",
      data: formdata,
    })
      .then((res) => {
        // handle success
        if (res.status === 201) {
          setIsOpen(false);
          snackRef.current.show();
        }
      })
      .catch((e) => {
        // handle error
        snackbarRef.current.show();
      });
  };

  const handleClose = () => {
    clearErrors("name");
    clearErrors("email");
    clearErrors("password");
    clearErrors("conf_password");
    setIsOpen(false);
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto z-30"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4 md:w-1/2 w-screen">
        <span
          className="text-2xl flex justify-end cursor-pointer font-bold"
          onClick={() => handleClose()}
        >
          &times;
        </span>
        <h3 className="text-gray-500 text-3xl mt-4 text-center">Register</h3>

        <form
          className=" w-64 block mx-auto py-2 mt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label>Full Name</label>

          {errors.name && <p className="text-red-600">{errors.name.message}</p>}
          <input
            type="text"
            {...register("name", {
              required: "Full Name is required",
            })}
            placeholder="Full Name"
            style={{
              color: "#5f6061",
              border: errors.name ? "1px solid red" : "1px solid gray",
            }}
            className="w-[250px] my-2 p-2 rounded"
          />
          <label>Email Address</label>
          {errors.email && (
            <p className="text-red-600">{errors.email.message}</p>
          )}
          <input
            type="text"
            {...register("email", {
              required: "Email is required",
            })}
            placeholder="Email address"
            style={{
              color: "#5f6061",
              border: errors.email ? "1px solid red" : "1px solid gray",
            }}
            className="w-[250px] my-2 p-2 rounded"
          />
          <label>Password</label>
          {errors.password && (
            <p className="text-red-600">{errors.password.message}</p>
          )}
          <input
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            placeholder="Password"
            style={{
              color: "#5f6061",
              border: errors.password ? "1px solid red" : "1px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <label>Confirm Password</label>
          {errors.conf_password && (
            <p className="text-red-600">{errors.conf_password.message}</p>
          )}
          <input
            type="password"
            {...register("conf_password", {
              required: "Confirmation Password is required",
              validate: (val) => {
                if (watch("password") !== val) {
                  return "Your passwords do not match";
                }
              },
            })}
            placeholder="Confirm Password"
            style={{
              color: "#5f6061",
              border: errors.conf_password ? "1px solid red" : "1px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <input
            type="submit"
            value="Register"
            className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white"
          />
        </form>
        <Snackbar
          type="error"
          message="Email is already used!"
          ref={snackbarRef}
        />
      </div>
    </div>
  );
}
