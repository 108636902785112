import React, { PureComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

export default function Classes() {
  const bookings = useSelector((state) => state.bookings);
  const classesData = useMemo(() => {
    let obj = [];
    for (let i = 0; i < bookings.length; i++) {
      let object = {};
      let flag = 0;
      if (bookings[i].class_time[0]) {
        for (let j = 0; j < obj.length; j++) {
          if (obj[j].class == bookings[i].class_time[0].classes[0].name) {
            obj[j].value += 1;
            flag = 1;
          }
        }
        if (flag == 1) {
          continue;
        }
        object["class"] = bookings[i].class_time[0].classes[0].name;
        object["value"] = 1;
      }
      obj.push(object);
      object = {};
    }
    return obj;
  }, [bookings]);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={classesData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="class" />
        <PolarRadiusAxis />
        <Radar
          name="Mike"
          dataKey="value"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}
