import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useSelect } from "@react-three/drei";
import { Model } from "../Male";
import { useForm } from "react-hook-form";
import { BiFemaleSign, BiMaleSign } from "react-icons/bi";
import Slider from "react-input-slider";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addBody } from "../../redux/bodySlice";
import instance from "../../axiosInstance";
export default function Modal({ isOpen, setIsOpen, setSuccess }, props) {
  // Hold state for hovered and clicked events
  const user = useSelector((state) => state.user);
  const [hovered, hover] = useState(false);
  const [clicked, setClick] = useState(0);
  const [gender, setGender] = useState("male");
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [waist, setWaist] = useState(0);
  const [chest, setChest] = useState(0);
  const [hips, setHips] = useState(0);
  const [arms, setArms] = useState(0);
  const [neck, setNeck] = useState(0);
  const [thighs, setThighs] = useState(0);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const onSubmit = () => {
    const formData = new FormData();
    formData.append("gender", gender);
    formData.append("height", height);
    formData.append("weight", weight);
    formData.append("waist", waist);
    formData.append("chest", chest);
    formData.append("hips", hips);
    formData.append("arms", arms);
    formData.append("neck", neck);
    formData.append("thighs", thighs);
    formData.append("body_fat", 0);
    formData.append("date", formatDate(new Date()));
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/add-body-info`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(addBody(res.data.user_body));
        setSuccess(true);
        setIsOpen(false);
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  // Subscribe this component to the render-loop, rotate the mesh every frame
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto  h-screen z-30 bg-gray-700/90"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <span className="close" onClick={() => handleClose()}>
        &times;
      </span>
      <h4 className="text-gray-300 text-2xl  text-center mt-12">
        We are missing some data about your body!
      </h4>
      <div className="w-full flex flex-row  justify-center md:space-y-12  md:space-x-32 mt-4">
        <div className="md:flex hidden flex-col justify-evenly items-center">
          <Canvas>
            <mesh>
              <pointLight position={[10, 10, 10]} />
              <Model />
              <OrbitControls autorotateSpeed />
            </mesh>
          </Canvas>
          <div className="flex flex-row space-x-4">
            <button
              className={`rounded-lg px-3 py-2 ${
                clicked === 0 ? "bg-green-300" : "bg-gray-800"
              }`}
              onClick={() => {
                setClick(0);
                setGender("female");
              }}
            >
              <BiFemaleSign size={24} color="pink" />
            </button>
            <button
              className={`rounded-lg px-3 py-2 ${
                clicked === 1 ? "bg-green-300" : "bg-gray-800"
              }`}
              onClick={() => {
                setClick(1);
                setGender("male");
              }}
            >
              <BiMaleSign size={24} color="#4295f5" />
            </button>
          </div>
        </div>
        <div className="">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-2 md:w-fit md:mx-auto  mx-0"
          >
            <label>
              <h5 className="text-gray-200 text-xl">Weight</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={weight}
                onChange={(x) => setWeight(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Height</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={height}
                onChange={(x) => setHeight(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Waist</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={waist}
                onChange={(x) => setWaist(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Chest</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={chest}
                onChange={(x) => setChest(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Hips</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={hips}
                onChange={(x) => setHips(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Arms</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={arms}
                onChange={(x) => setArms(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Neck</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={neck}
                onChange={(x) => setNeck(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
            <label>
              <h5 className="text-gray-200 text-xl">Thighs</h5>
            </label>
            <div className="flex-col space-x-4">
              <input
                type="number"
                value={thighs}
                onChange={(x) => setThighs(x.target.value)}
                className="rounded-md px-4 mt-2"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-row justify-end space-x-6 px-12 md:mt-0 mt-4">
        <button
          className="bg-gray-700 p-2 rounded-md text-gray-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          Skip
        </button>
        <button
          className="bg-green-600 p-2 rounded-md  text-gray-100"
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
}
