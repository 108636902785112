import React from "react";
import { useNavigate } from "react-router-dom";

export default function Unavailble() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center align-middle h-screen space-y-12 bg-gray-300">
      <div>
        <img src="./logo.png" width={128} height={128} />
      </div>
      <div className="px-12 tracking-wider text-xl">
        <h5>
          Seems your subscription has expired! Contact with the gym in order to
          be able to use our app.
        </h5>
        <h5 className="text-center">Regards, Barbox</h5>
        <div className="w-full flex justify-center">
          <button
            className="mx-auto  mt-12 border-2 cursor-pointer border-red-500 px-4 py-2 rounded-lg text-red-700"
            onClick={() => navigate("/")}
          >
            Back to Home Page
          </button>
        </div>
      </div>
    </div>
  );
}
