/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Mandrake (https://sketchfab.com/mandrake_3d)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/male-body-base-mesh-highpoly-9311f4f8fa1a4fe4bb0027ff7e8fd795
title: Male body base mesh (highpoly)
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/male.gltf");
  return (
    <group {...props} dispose={null} position={[0, 1.5, 0]} scale={0.6}>
      <group rotation={[-Math.PI / 2, 0, 0.2]}>
        <mesh
          geometry={nodes.Object_2.geometry}
          material={materials.material_0}
        />
        <mesh
          geometry={nodes.Object_3.geometry}
          material={materials.material_0}
        />

        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials.material_0}
        />
        <mesh
          geometry={nodes.Object_5.geometry}
          material={materials.material_0}
        />
        <mesh
          geometry={nodes.Object_6.geometry}
          material={materials.material_0}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/male.gltf");
