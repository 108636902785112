import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, removeToken } from "../redux/userSlice";
import axios from "axios";
import { useSelector } from "react-redux";
import { logoutClassTime } from "../redux/classTimeSlice";
import { logoutBookings } from "../redux/bookingsSlice";
import { logoutBody } from "../redux/bodySlice";
import instance from "../axiosInstance";
export default function UserNav() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleLogout = (e) => {
    e.preventDefault();
    console.log(user.token);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/logout`,
      method: "POST",

      headers: {
        Authorization: "Bearer " + user.token,
      },
    })
      .then((res) => {
        // handle success
        if (res.status === 200) {
          dispatch(logout());
          dispatch(logoutClassTime());
          dispatch(logoutBookings());
          dispatch(logoutBody());
          navigate("/");
        }
      })
      .catch((e) => {
        dispatch(logout());
        dispatch(logoutClassTime());
        dispatch(logoutBookings());
        dispatch(logoutBody());
        navigate("/");
      });
  };

  const forgotPass = () => {
    dispatch(removeToken());
  };
  return (
    <div>
      <img
        src={user.avatar === "default.jpg" ? "/Avatar.png" : user.avatar}
        className={`inline-block rounded-full h-12 w-12 mx-auto object-cover transition-all duration-300 z-10 mr-2`}
      />
      <button
        onClick={() => setOpen(!open)}
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        type="button"
      >
        {user.name}
        <svg
          className="ml-2 w-4 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      <div
        id="dropdown"
        className={`z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 ${
          open ? "block" : "hidden"
        }`}
        data-popper-reference-hidden=""
        data-popper-escaped=""
        data-popper-placement="bottom"
        style={{
          position: "absolute",
          right: "0px",
          margin: "0px",
        }}
      >
        <ul
          className="py-1 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefault"
        >
          <li>
            <a
              className="cursor-pointer block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => navigate("/")}
            >
              Dashboard
            </a>
          </li>
          <li>
            <a
              className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              onClick={() => navigate("/settings")}
            >
              Settings
            </a>
          </li>
          <li>
            <a
              className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
              onClick={() => navigate("/history")}
            >
              History
            </a>
          </li>
          <li>
            <button
              className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-end w-full"
              onClick={(e) => handleLogout(e)}
            >
              Sign out
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
