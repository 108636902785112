import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: "",
    email: "",
    avatar: "",
    sub: "",
    token: "",
    photo: "",
    role: "",
    personalInfo: {},
    auth: false,
    exp: "",
  },
  reducers: {
    login: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.token = action.payload.token;
      state.avatar = action.payload.avatar;
      state.sub = action.payload.sub;
      state.role = action.payload.roles;
      state.auth = action.payload.auth;
      state.exp = action.payload.exp;
    },
    addPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    updatePhoto: (state, action) => {
      state.avatar = action.payload;
    },
    updateData: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    removeToken: (state, action) => {
      state.token = "";
    },
    isNotLogged: (state, action) => {
      state.auth = false;
    },
    logout: (state, action) => {
      state.email = "";
      state.name = "";
      state.token = "";
      state.avatar = "";
      state.sub = 0;
      state.personalInfo = {};
      state.auth = false;
      state.exp = "";
    },
  },
});

export const {
  login,
  logout,
  updatePhoto,
  removeToken,
  addPersonalInfo,
  updateData,
  isNotLogged,
} = userSlice.actions;
export default userSlice.reducer;
