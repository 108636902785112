import React, { useState, useEffect, useRef, useMemo } from "react";
import "../css/courses.css";
import CourseModal from "./courseModal";
import { addDays, format, getDate, parseISO, startOfWeek } from "date-fns";
import { GrPrevious, GrNext } from "react-icons/gr";
import Schedule from "./schedule/schedule";
import Snackbar from "./Snackbar/Snackbar";
export default function CourseTable() {
  const [show, setShow] = useState(false);
  const [courseClick, setCourseClick] = useState({
    title: "",
    color: "",
    description: "",
    index: 0,
    time: "",
  });
  const [weekStart, setWeekStart] = useState("");
  const [weekEnd, setWeekEnd] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const handleChange = (course, index, time) => {
    setShow(true);
    setCourseClick({
      ...courseClick,
      title: course.title,
      color: course.color,
      description: course.description,
      time: time,
      index: index,
    });
  };
  const getWeekDate = (d) => {
    var curr = new Date(d); // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));
    setWeekStart(formatDate(firstday));
    setWeekEnd(formatDate(lastday));
  };
  // const currentTableData = useMemo(() => {
  //   var curr = new Date(); // get current date
  //   var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
  //   var last = first + 6; // last day is the first day + 6
  //   var firstday = new Date(curr.setDate(first));

  //   var lastday = new Date(curr.setDate(last));
  //   setWeekStart(formatDate(firstday));
  //   setWeekEnd(formatDate(lastday));
  //   return [formatDate(firstday), formatDate(lastday)];
  // }, []);
  useEffect(() => {
    getWeekDate(Date.now());
  }, []);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  const addDays = (date, daysAdd) => {
    var datearray = date.split("/");
    var newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
    var d = new Date(newdate);
    d.setDate(d.getDate() + daysAdd);
    return d;
  };

  const snackbarRef = useRef();
  const snackbarRef2 = useRef();
  useEffect(() => {
    if (success) {
      snackbarRef.current.show();
      setSuccess(false);
    } else if (error) {
      snackbarRef2.current.show();
      setError(false);
    }
  }, [success, error]);

  return (
    <div className="2xl:h-screen flex  flex-col space-y-8  justify-center text-center overflow-hidden">
      <h2 className="title md:w-1/2 w-full md:px-0 px-12  py-12  mx-auto mt-8 text-3xl text-bold">
        Schedule
      </h2>
      <div className="w-screen mt-4 mx-auto text-center">
        <GrPrevious
          className="inline-block cursor-pointer md:mr-12 mr-2"
          onClick={() => getWeekDate(addDays(weekStart, -7))}
        />
        <h3 className="inline-block">Week's Schedule:</h3>
        <h3 className="inline-block" style={{ fontWeight: 800 }}>
          {weekStart}-
        </h3>
        <h3 className="inline-block" style={{ fontWeight: 800 }}>
          {weekEnd}
        </h3>
        <GrNext
          className="inline-block cursor-pointer md:ml-12 ml-2"
          onClick={() => getWeekDate(addDays(weekStart, 6))}
        />
      </div>
      <div className="overflow-x-auto relative mt-2 mb-12">
        <Schedule
          weekStart={weekStart}
          weekEnd={weekEnd}
          setMessage={setMessage}
          setSuccess={setSuccess}
          setError={setError}
        />
      </div>

      <Snackbar type="success" message={message} ref={snackbarRef} />
      <Snackbar type="error" message={message} ref={snackbarRef2} />
    </div>
  );
}
