import React, { useRef, useState } from "react";
import "../css/loginModal.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux/es/exports";
import axios from "axios";
import Snackbar from "./Snackbar/Snackbar";
import { login } from "../redux/userSlice";
import ForgotPassword from "./forgotPassword";
import AuthApi from "../AuthApi";
import instance from "../axiosInstance";

export default function LoginModal({
  isOpen,
  setIsOpen,
  setSuccess,
  setMessage,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Auth = React.useContext(AuthApi);
  const [openF, setOpenF] = useState(false);
  const [message, setMessage2] = useState("");
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const snackbarRef = useRef(null);
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/login`,
      method: "POST",
      data: formdata,
    })
      .then((res) => {
        // handle success
        if (res.status === 202) {
          setIsOpen(false);
          const curr = new Date();
          let name = res.data.user.name;
          let email = res.data.user.email;
          let avatar = res.data.user.avatar;
          let sub = res.data.user.subscription;
          let token = res.data.token;
          let auth = true;
          let exp = new Date(curr.getTime() + 86400000).toString();
          if (sub <= -4) {
            navigate("/unavailable");
          } else {
            dispatch(login({ name, email, avatar, sub, token, auth, exp }));
            navigate("/");
          }
        }
      })
      .catch((e) => {
        // handle error
        setMessage2(e.response.data.message);
        snackbarRef.current.show();
      });
  };
  const handleClose = () => {
    clearErrors("name");
    clearErrors("email");
    clearErrors("password");
    clearErrors("conf_password");
    setIsOpen(false);
  };
  return (
    <>
      <div
        id="myModal"
        className="modal mx-auto  h-screen z-30"
        style={{ display: isOpen && !openF ? "block" : "none" }}
      >
        <div className="modal-content mx-auto mt-32 border-2 rounded p-4  md:w-1/2 w-screen">
          <span
            className="text-2xl flex justify-end cursor-pointer font-bold"
            onClick={() => handleClose()}
          >
            &times;
          </span>
          <h3 className="text-gray-500 text-3xl mt-4 text-center">Log in</h3>
          <form
            className=" w-64 block mx-auto py-2 mt-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label>Email Address</label>
            {errors.email && (
              <p className="text-red-600">{errors.email.message}</p>
            )}
            <input
              type="text"
              {...register("email", {
                required: "Email is required",
              })}
              placeholder="Email address"
              style={{
                color: "#5f6061",
                border: errors.email ? "1px solid red" : "1px solid gray",
              }}
              className="w-[250px] my-2 p-2 rounded"
            />
            <label>Password</label>
            {errors.password && (
              <p className="text-red-600">{errors.password.message}</p>
            )}
            <input
              type="password"
              {...register("password", {
                required: "Password is required",
              })}
              placeholder="Password"
              style={{
                color: "#5f6061",
                border: errors.password ? "1px solid red" : "1px solid gray",
              }}
              className="p-2 w-[250px] my-2 rounded"
            />
            <a
              className="text-center block cursor-pointer text-blue-300"
              onClick={() => setOpenF(true)}
            >
              Forgot your password?
            </a>
            <input
              type="submit"
              value="Log in"
              className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white"
            />
          </form>
          <Snackbar type="error" message={message} ref={snackbarRef} />
        </div>
      </div>
      <ForgotPassword
        isOpen={openF}
        setIsOpen={setOpenF}
        setlogOpen={setIsOpen}
        setSuccess={setSuccess}
        setMessage={setMessage}
      />
    </>
  );
}
