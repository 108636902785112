import React from "react";
import HistoryTable from "../components/history/historyTable";
import Navbar from "../components/navbar";
import SettingsSession from "../components/settingsSession";

export default function History() {
  return (
    <div className="bg-gray-200  z-0 text-gray-700 h-screen 2xl:snap-y xl:snap-mandatory overflow-y-scroll overflow-x-hidden scrollbar scrollbar-track-gray-400/20 scrollbar-thumb-[#f72d0a]/80">
      <Navbar logged={true} />
      <HistoryTable />
    </div>
  );
}
