import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

export default function Days() {
  const bookings = useSelector((state) => state.bookings);
  const classTimes = useSelector((state) => state.classTimes);
  const daysWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const COLORS = ["#eb4034", "#f2c43a", "#07ad39", "#2dedc7", "#2d8ded"];
  const daysData = useMemo(() => {
    let obj = [
      { day: "Monday", value: 0 },
      { day: "Tuesday", value: 0 },
      { day: "Wednesday", value: 0 },
      { day: "Thursday", value: 0 },
      { day: "Friday", value: 0 },
    ];

    for (let i = 0; i < bookings.length; i++) {
      var dateParts = bookings[i].date.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      var day = dateObject.getDay();
      for (let j = 0; j < obj.length; j++) {
        if (obj[j].day == daysWeek[day]) {
          obj[j].value += 1;
        }
      }
    }
    return obj;
  }, [bookings]);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          {console.log(payload[0].day)}
          <label>{`${payload[0].name} : ${payload[0].value} books`}</label>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <PieChart width={730} height={250}>
        <Pie
          data={daysData}
          dataKey="value"
          nameKey="day"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#82ca9d"
          label
        >
          {daysData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </PieChart>
    </div>
  );
}
