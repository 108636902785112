import "./App.css";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import User from "./pages/user";
import Settings from "./pages/settings";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import History from "./pages/history";
import { useEffect } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import { getAllclassTimes, logoutClassTime } from "./redux/classTimeSlice";
import { getAllBookings, logoutBookings } from "./redux/bookingsSlice";
import { getBody, logoutBody } from "./redux/bodySlice";
import { addPersonalInfo, isNotLogged, logout } from "./redux/userSlice";
import { getAllWods, logoutWods } from "./redux/wodSlice";
import { getAllPosts, logoutPosts } from "./redux/postSlice";
import { getAllHours, logoutHours } from "./redux/hoursSlice";
import Unavailble from "./pages/unavailble";
import AuthApi from "./AuthApi";
import instance from "./axiosInstance";
import CookieConsent from "react-cookie-consent";
import { getAllNotifications } from "./redux/notificationsSlice";
const TRACKING_ID = "UA-143341352-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDate = () => {
      if (user.token) {
        instance({
          // url of the api endpoint (can be changed)
          url: "/web/class-times",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            let classTimes = res.data.class_times;
            dispatch(getAllclassTimes({ classTimes }));
          })
          .catch((e) => {
            // handle error
            console.log(e);
          });

        instance({
          // url of the api endpoint (can be changed)
          url: "/web/bookings",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            dispatch(getAllBookings(res.data.bookings));
          })
          .catch((e) => {
            // handle error
            dispatch(logoutBookings());
          });
          instance({
            // url of the api endpoint (can be changed)
            url: "/web/notifications",
            method: "GET",
            headers: { Authorization: "Bearer " + user.token },
          })
            .then((res) => {
              // handle success
              dispatch(getAllNotifications(res.data.notifications));
            })
            .catch((e) => {
              // handle error
              dispatch(logoutBookings());
            });
        instance({
          // url of the api endpoint (can be changed)
          url: "/web/user/settings",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            dispatch(getBody(res.data.user_body));
            dispatch(addPersonalInfo(res.data.user_personal_info));
          })
          .catch((e) => {
            // handle error
            if (e.response.status === 401) {
              dispatch(logout());
              dispatch(logoutBody());
              dispatch(logoutBookings());
              dispatch(logoutClassTime());
              dispatch(logoutHours());
              dispatch(logoutWods());
              dispatch(logoutPosts());
            }
          });

        instance({
          // url of the api endpoint (can be changed)
          url: "/web/wods",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            dispatch(getAllWods(res.data.wods));
          })
          .catch((e) => {
            // handle error
            console.error(e);
          });

        instance({
          // url of the api endpoint (can be changed)
          url: "/web/posts",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            dispatch(getAllPosts(res.data.posts));
          })
          .catch((e) => {
            // handle error
            console.error(e);
          });

        instance({
          // url of the api endpoint (can be changed)
          url: "/web/table-hours",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            dispatch(getAllHours(res.data.hours));
          })
          .catch((e) => {
            // handle error
            console.error(e);
          });
      }
    };
    fetchDate();
  }, [user.auth]);

  useEffect(() => {
    if (user.auth) {
      setTimeout(function () {
        dispatch(isNotLogged());
        dispatch(logout());
        dispatch(logoutBody());
        dispatch(logoutBookings());
        dispatch(logoutClassTime());
        dispatch(logoutHours());
        dispatch(logoutWods());
        dispatch(logoutPosts());
      }, new Date(user.exp) - new Date());
    }
  }, [user.auth]);
  return (
    <BrowserRouter>
      <CookieConsent>This website uses the default cookies.</CookieConsent>
      <Routes>
        {user.token && user.auth ? (
          <>
            <Route path="/" element={<User />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/history" element={<History />} />
          </>
        ) : (
          <>
            <Route path="/unavailable" element={<Unavailble />} />
            <Route path="*" element={<Home />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
