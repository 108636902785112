import { createSlice } from "@reduxjs/toolkit";

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState: [],
  reducers: {
    getAllBookings: (state, action) => {
      return action.payload;
    },
    addBooking: (state, action) => {
      return [...state, action.payload];
    },
    deleteBooking: (state, action) => {
      return state.filter((element) => element.id !== action.payload);
    },
    logoutBookings: (state, action) => {
      return [];
    },
  },
});

export const { getAllBookings, logoutBookings, addBooking, deleteBooking } =
  bookingsSlice.actions;
export default bookingsSlice.reducer;
