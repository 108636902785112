import React, { useState, useEffect, useMemo } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
function WodOfTheDay({ Open, setOpen, date, wod }) {
  return (
    <div
      id="myModal"
      className="modal mx-auto  h-screen z-30"
      style={{ display: Open ? "block" : "none" }}
    >
      <div className="modal-content mx-auto md:mt-0 mt-0 border-2 rounded p-0 md:w-1/2 w-screen md:h-full h-screen">
        <span
          className="text-3xl flex justify-end cursor-pointer font-bold"
          onClick={() => setOpen(false)}
        >
          &times;
        </span>
        <h3 className="text-gray-500 text-3xl mt-2 text-center">
          WOD of {date}
        </h3>
        {wod.length > 0 ? (
          <motion.div
            className={`block py-12 w-full min-h-[650px] max-h-[900px]`}
            initial={{ x: -200, opacity: 0 }}
            transition={{
              duration: 1.2,
            }}
            whileInView={{ opacity: 1, x: 0 }}
          >
            <h2 className="text-2xl tracking-widest text-center self-center basis-full text-black">
              {wod[0].title}
            </h2>

            <h3 className="text-black text-center">{wod[0].date}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(wod[0].body, {
                  USE_PROFILES: { html: true },
                }),
              }}
              className="mt-4 text-black text-center"
            ></div>
          </motion.div>
        ) : (
          <h3 className="text-center mt-2">No Data found!</h3>
        )}
      </div>
    </div>
  );
}
export default function SingleBook({ book }) {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const wods = useSelector((state) => state.wod);
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const currentWod = useMemo(() => {
    return wods.filter((wod) => wod.date === book.date);
  }, [wods]);
  return (
    <button className="flex flex-col text-left w-full bg-[#EFEFEF] p-3 rounded-lg">
      <div className=" w-full" onClick={() => setIsOpen((prev) => !prev)}>
        <div className="text-xl  flex flex-row justify-between items-center">
          <div>{book.date}</div>

          <div>
            <AiOutlineArrowDown
              className={`text-2xl text-gray-700 border-2 border-gray-700 rounded-xl cursor-pointer ${
                isOpen ? "rotate-180" : "rotate-0"
              } transition-all duration-500 ease-in-out`}
            />
          </div>
        </div>
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key={isOpen ? "minus" : "plus"}
            initial={{
              rotate: isOpen ? -90 : 90,
            }}
            animate={{
              zIndex: 1,
              rotate: 0,
              transition: {
                type: "tween",
                duration: 3,
                ease: "circOut",
              },
            }}
            exit={{
              zIndex: 0,
              rotate: isOpen ? -90 : 90,
              transition: {
                type: "tween",
                duration: 3,
                ease: "circIn",
              },
            }}
          ></motion.div>
        </AnimatePresence>
      </div>
      <WodOfTheDay
        Open={open}
        setOpen={setOpen}
        date={book.date}
        wod={currentWod}
      />
      {isOpen && (
        <div className="text-lg font-light bg-gray-600 rounded-xl p-3 mt-2">
          {book.class_time[0] ? (
            <>
              <div className="flex flex-row gap-4 justify-between items-center text-center text-gray-200 py-4">
                <div>{book.class_time[0].days}</div>
                <div>{book.class_time[0].classes[0].name}</div>
                <div>{book.class_time[0].start_time}</div>
                <div>{book.class_time[0].end_time}</div>
              </div>
              <div
                className="text-gray-200 cursor-pointer text-center "
                onClick={() => setOpen(true)}
              >
                <h3 className="border-2 rounded-lg">Wod of the day</h3>
              </div>
            </>
          ) : (
            <h3>No data</h3>
          )}
        </div>
      )}
    </button>
  );
}
