import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addClassTime, updateClassTime } from "../../redux/classTimeSlice";
import BookModal from "./bookModal";
import { format } from "date-fns";
import Snackbar from "../Snackbar/Snackbar";

export default function Square({
  start,
  end,
  days,
  name,
  course,
  weekStart,
  weekEnd,
  number,
  setMessage,
  setSuccess,
  setError,
}) {
  const [cl, setClass] = useState(name);
  const [flag, setFlage] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const snackbarRef = useRef();
  const snackbarRef2 = useRef();
  const [date2, setDate] = useState("");
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  useEffect(() => {
    if (weekStart) {
      var datearray = weekStart.split("/");
      var newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
      let date = new Date(newdate);
      setDate(formatDate(addDays(date, number)));
    }
  }, [weekStart]);
  const addDays = (date, days) => {
    var date2 = new Date(date);
    date2.setDate(date2.getDate() + number);
    return date2;
  };
  return (
    <>
      <div
        className={`h-16 border-2 flex justify-center items-center rounded-xl transition-all duration-500 cursor-pointer`}
        style={{
          backgroundColor: course
            ? course.classes.length > 0
              ? course.classes[0].color
              : "red"
            : "gray",
          color: "white",
        }}
        onClick={() => setOpen(true)}
      >
        {course
          ? course.classes.length > 0
            ? course.classes[0].name
            : "Closed"
          : "Closed"}
      </div>
      {course ? (
        <BookModal
          setIsOpen={setOpen}
          isOpen={open}
          course={course}
          setSuccess={setSuccess}
          date={date2}
          setError={setError}
          setMessage={setMessage}
        />
      ) : (
        ""
      )}
    </>
  );
}
