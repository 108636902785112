import React from "react";
import { useState } from "react";
import Account from "./settings/account";
import Body from "./settings/body";
import PersonalInfo from "./settings/personalInfo";

export default function SettingsSession() {
  const [active, setActive] = useState(1);
  const pageChange = (index) => {
    switch (index) {
      case 1:
        return <PersonalInfo />;
      case 2:
        return <Account />;
      case 3:
        return <Body />;
    }
  };
  return (
    <div>
      <div className="flex flex-row justify-evenly items-center">
        <div>
          <h3
            className={`${
              active === 1
                ? "text-red-600 border-2 border-red-600 rounded-md p-2 font-semibold transition-all duration-300 cursor-pointer"
                : "text-gray-400 cursor-pointer transition-all duration-300 p-2"
            }`}
            onClick={() => setActive(1)}
          >
            Personal Information
          </h3>
        </div>
        <div>
          <h3
            className={`${
              active === 2
                ? "text-red-600 border-2 border-red-600 rounded-md p-2 font-semibold transition-all duration-300 cursor-pointer"
                : "text-gray-400 cursor-pointer transition-all duration-300 p-2"
            }`}
            onClick={() => setActive(2)}
          >
            Login & Security
          </h3>
        </div>
        <div>
          <h3
            className={`${
              active === 3
                ? "text-red-600 border-2 border-red-600 rounded-md p-2 font-semibold transition-all duration-300 cursor-pointer"
                : "text-gray-400 cursor-pointer p-2 transition-all duration-300"
            }`}
            onClick={() => setActive(3)}
          >
            Body Data
          </h3>
        </div>
      </div>
      {pageChange(active)}
    </div>
  );
}
