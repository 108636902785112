import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { AiOutlineArrowDown } from "react-icons/ai";
import SingleBook from "./singleBook";
import Pagination from "../pagination/pagination";

let PageSize = 10;
export default function AllBooks() {
  const bookings = useSelector((state) => state.bookings);
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return bookings.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  return (
    <div>
      <div className="flex flex-col justify-start items-center mt-16 lg:px-64 px-12 ">
        <div className="border-b-2 w-full">
          <h4 className="text-gray-500 text-2xl">Your bookings</h4>
        </div>

        <div className=" flex flex-col gap-0 mt-4  items-center w-full h-screen">
          {currentTableData.map((cl) => {
            return <SingleBook book={cl} />;
          })}
          <Pagination
            className="w-full flex justify-center mt-2 mb-12"
            currentPage={currentPage}
            totalCount={bookings.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <motion.div
        initial={{ x: -200, opacity: 0, scale: 0 }}
        transition={{
          duration: 1.2,
        }}
        whileInView={{ opacity: 1, x: 0, scale: 1 }}
        viewport={{ once: true }}
        className="md:flex  hidden flex-col justify-start items-center mt-16 lg:px-64 px-12 "
      >
        <div className="border-b-2 w-full">
          <h4 className="text-gray-500 text-2xl">Your bookings</h4>
        </div>

        <div className=" flex flex-col gap-0 mt-4  items-center w-full h-screen">
          {currentTableData.map((cl) => {
            return <SingleBook book={cl} />;
          })}
          <Pagination
            className="w-full flex justify-center mt-2 mb-12"
            currentPage={currentPage}
            totalCount={bookings.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </motion.div>
    </div>
  );
}
