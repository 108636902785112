import { createSlice } from "@reduxjs/toolkit";

export const bodySlice = createSlice({
  name: "body",
  initialState: [],
  reducers: {
    getBody: (state, action) => {
      return action.payload;
    },
    addBody: (state, action) => {
      return [...state, action.payload];
    },
    logoutBody: (state, action) => {
      return [];
    },
  },
});

export const { getBody, logoutBody, addBody } = bodySlice.actions;
export default bodySlice.reducer;
