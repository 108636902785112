import React, { useState, useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import BackgroundCircles from "./BackgroundCircles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { updatePhoto } from "../redux/userSlice";
import Snackbar from "./Snackbar/Snackbar";
import { addBooking } from "../redux/bookingsSlice";
import SelectForm from "./select/selectForm";

export default function Hero({ logged }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classTimes = useSelector((state) => state.classTimes);
  const books = useSelector((state) => state.bookings);
  const [hover, setHover] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [lesson, setLesson] = useState(0);
  const snackbarRef = useRef(null);
  const snackbarRef2 = useRef(null);
  const [message, setMessage] = useState("");
  const [todayLesson, setTodayLesson] = useState([]);
  const [text, count] = useTypewriter({
    words: [
      "Welcome to Barbox",
      "Strength & Conditioning Club",
      "Cross Training Gym At Rethymno",
    ],
    loop: true,
    delaySpeed: 2000,
  });
  const [loggedText, count2] = useTypewriter({
    words: [`Subscription: ${user.sub} days`],
    loop: true,
    delaySpeed: 2000,
  });

  return (
    <div
      className="h-screen flex flex-col items-center justify-center text-center overflow-hidden"
      // style={{
      //   background:
      //     "linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(main_bg.jpeg) center center fixed",
      // }}
    >
      <BackgroundCircles />

      <img
        src="logo.png"
        className="relative rounded-full h-32 w-32 mx-auto object-cover"
      />

      <div className="flex flex-col justify-center items-center">
        <div className="z-20">
          <h2 className="text-sm uppercase text-gray-500 pb-2 tracking-[15px]">
            Cross Box
          </h2>
          <h1 className="text-2xl lg:text-6xl font-semibold px-10">
            <span className="mt-3">{logged ? loggedText : text}</span>
            <Cursor cursorColor="#f72d0a" />
          </h1>
          <div className="pt-5">
            <a href="#classes">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Classes
              </button>
            </a>
            <a href="#statistics">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Statistics
              </button>
            </a>
            <a href="#contact">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Contact
              </button>
            </a>
            <a href="#location">
              <button className="px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-widest text-gray-500 transition-all hover:border-[#f72d0a] hover:text-[#f72d0a]/40">
                Location
              </button>
            </a>
          </div>
        </div>
        {/* <div
        className="text-center text-white w-full md:pt-0 pt-32"
        style={{ justifyContent: "center" }}
      >
        <h1
          className="md:text-5xl text-3xl mx-auto px-2"
          style={{
            fontWeight: 700,
            letterSpacing: "0.3vh",
            textShadow: ".1rem .1rem .8rem black",
          }}
        >
          Welcome to BarBox
          <br />
          Strength & Conditioning Club
        </h1>
      </div> */}
        {/* {logged ? (
          <>
            <div
              className="text-center text-white w-full  pt-24"
              style={{ justifyContent: "center" }}
            >
              <h1
                className="text-red-600 p-4 mx-auto text-3xl"
                style={{
                  fontWeight: 700,
                  letterSpacing: "0.3rem",
                  textShadow: ".1rem .1rem .8rem black",
                }}
              >
                Your subscription
                <br />
                {user.sub} days
              </h1>
            </div>
            <div
              className="text-center text-white w-full md:pt-32 pt-8"
              style={{ justifyContent: "center" }}
            >
              <h5 className="text-white p-4 mx-auto text-3xl font-bold">
                Book your Class
              </h5>
              <div className="grid md:grid-cols-3  mx-auto mt-8 items-center align-middle xl:px-96 px-16 gap-4">
                <div>
                  <ReactDatePicker
                    onChange={(date) => dateChange(date)}
                    selected={startDate}
                    filterDate={isWeekday}
                    withPortal
                    className="text-black p-2 cursor-pointer rounded inline-block w-full"
                    placeholderText="Date"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </div>
                <div>
                  <select
                    className="p-2 rounded text-black md:mt-0 mt-4 md:mb-0 mb-4 w-full"
                    placeholder="Hour"
                    value={lesson}
                    onChange={(e) => setLesson(e.target.value)}
                  >
                    {todayLesson.length > 0 ? (
                      todayLesson.map((lesson) => (
                        <option value={JSON.stringify(lesson)}>
                          {lesson.start_time}-{lesson.end_time} Lesson:
                          {lesson.classes[0].name}
                        </option>
                      ))
                    ) : (
                      <option className="text-black" disabled>
                        No Available Lesson
                      </option>
                    )}
                  </select>
                </div>
                <div>
                  <button
                    className="bg-white text-black rounded p-2 w-32 hover:bg-red-500 hover:text-white transition-all duration-250 ease-in"
                    onClick={() => submitBook()}
                  >
                    Book
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <div
            className="text-center text-white w-full  pt-24"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-red-600 p-4 mx-auto text-3xl"
              style={{
                fontWeight: 700,
                letterSpacing: "0.3rem",
                textShadow: ".1rem .1rem .8rem black",
              }}
            >
              Cross Training
              <br />
              Gym at Rethymno
            </h1>
          </div>
          <div
            className="text-center text-white w-full md:pt-32 pt-8"
            style={{ justifyContent: "center" }}
          >
            <button
              className="rounded text-gray-200 p-4 bg-black hover:text-gray-800 hover:bg-white ease duration-500 text-xl"
              style={{
                border: "1px solid black",
                fontWeight: 700,
              }}
            >
              Get started
            </button>
          </div> */}
      </div>

      <Snackbar
        type="success"
        message="Your book has been accepted!"
        ref={snackbarRef}
      />
      <Snackbar type="error" message={message} ref={snackbarRef2} />
    </div>
  );
}
