import React, { useRef, useState } from "react";
import "../css/courses.css";
import { motion } from "framer-motion";
import { AiFillPhone } from "react-icons/ai";
import { FaEnvelope, FaMapPin } from "react-icons/fa";
import { useForm } from "react-hook-form";
import axios from "axios";
import Snackbar from "./Snackbar/Snackbar";
import instance from "../axiosInstance";
export default function Contact() {
  const [message, setMessage] = useState("");
  const s1 = useRef();
  const s2 = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("subject", data.subject);
    formData.append("message", data.message);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/email-form`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        s1.current.show();
      })
      .catch((e) => {
        setMessage(e.response.data.message);
        s2.current.show();
      });
  };
  return (
    <div className="h-screen flex  flex-col space-y-12  justify-center text-center overflow-hidden">
      <motion.div
        initial={{ x: -200, opacity: 0 }}
        transition={{
          duration: 1.2,
        }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="title md:w-1/2 w-full md:px-0 px-12    mx-auto mt-8 text-3xl text-bold"
      >
        Contact
      </motion.div>
      <div className="flex flex-col space-y-10 justify-center items-center mb-12 mt-24 pt-4">
        <div className="flex items-center justify-center space-x-5 md:text-sm">
          <AiFillPhone className="text-[#f72d0a] h-7 w-7 animate-pulse md:text-sm" />
          <p className="text-md lg:text-2xl">2831181071</p>
        </div>
        <div className="flex items-center justify-center space-x-5">
          <FaEnvelope className="text-[#f72d0a] h-7 w-7 animate-pulse" />
          <p className="text-md lg:text-2xl">barboxrethimno@gmail.com</p>
        </div>
        <div className="flex items-center justify-center space-x-5">
          <FaMapPin className="text-[#f72d0a] h-7 w-7 animate-pulse" />
          <p className="text-md lg:text-2xl">Άρη Βελουχιώτη 2, Ρέθυμνο</p>
        </div>
        <Snackbar type="success" message={message} ref={s1} />
        <Snackbar type="error" message={message} ref={s2} />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-2 md:w-fit mx-auto"
      >
        <div className="md:flex-row md:flex flex-col md:space-x-2 space-x-0 md:space-y-0 space-y-2 mx-0">
          <input
            {...register("name")}
            placeholder="Name"
            className="outline-none bg-slate-400/10 md:w-full w-screen rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
          />
          <input
            {...register("email")}
            placeholder="Email"
            className="outline-none bg-slate-400/10 rounded-sm md:w-full w-screen border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
          />
        </div>
        <input
          type="text"
          {...register("subject")}
          placeholder="Subject"
          className="outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
        />
        <textarea
          {...register("message")}
          placeholder="Message"
          className="outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
        />
        <button
          type="submit"
          className="bg-[#f72d0a] py-5 md:px-10 px-2 rounded-md text-gray-200 font-bold text-lg"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
