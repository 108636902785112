import React, { useState, useEffect } from "react";
import SingleButton from "./singleButton";

export default function PhoneSchedule({
  data,
  week,
  classTimes,
  weekStart,
  weekEnd,
  setSuccess,
  setError,
  setMessage,
}) {
  return (
    <>
      <div>
        {week.map((day, index) => {
          return (
            <SingleButton
              day={day.day}
              classTimes={classTimes}
              weekStart={weekStart}
              weekEnd={weekEnd}
              number={index}
              setSuccess={setSuccess}
              setError={setError}
              setMessage={setMessage}
            />
          );
        })}
      </div>
    </>
  );
}
