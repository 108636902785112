import { createSlice } from "@reduxjs/toolkit";

export const hoursSlice = createSlice({
  name: "hours",
  initialState: [],
  reducers: {
    getAllHours: (state, action) => {
      return action.payload.sort(function (a, b) {
        return (
          new Date("1970/01/01 " + a.start_time) -
          new Date("1970/01/01 " + b.start_time)
        );
      });
    },
    updateHours: (state, action) => {
      return state.map((item, index) => {
        return action.payload.id === item.id
          ? {
              ...action.payload,
            }
          : item;
      });
    },
    addHours: (state, action) => {
      state = [...state, action.payload];
      return state.sort(function (a, b) {
        return (
          new Date("1970/01/01 " + a.start_time) -
          new Date("1970/01/01 " + b.start_time)
        );
      });
    },
    deleteHours: (state, action) => {
      return state.filter((element) => element.id !== action.payload);
    },
    logoutHours: (state, action) => {
      return [];
    },
  },
});

export const { getAllHours, logoutHours, updateHours, addHours, deleteHours } =
  hoursSlice.actions;
export default hoursSlice.reducer;
