import axios from "axios";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateData, updatePhoto } from "../../redux/userSlice";
import { motion } from "framer-motion";
import Snackbar from "../Snackbar/Snackbar";
import instance from "../../axiosInstance";
export default function Account() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [hover, setHover] = useState(false);
  const [username, setUsername] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [conf, setPass] = useState("");
  const [p, setP] = useState("");
  const [cp, setCp] = useState("");
  const [np, setNp] = useState("");
  const [message, setMessage] = useState("");
  const s1 = useRef();
  const s2 = useRef();
  const updatePhoto2 = (e) => {
    const formData = new FormData();
    console.log(e);
    formData.append("avatar", e.target.files[0]);
    axios
      .post(
        "https://api.barbox.gr/api/web/user/settings/upload-avatar",
        formData,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((res) => dispatch(updatePhoto(res.data.user.avatar)))
      .catch((err) => console.log(err));
  };
  const changePassword = () => {
    const formdata = new FormData();
    formdata.append("old_password", p);
    formdata.append("password", np);
    formdata.append("password_confirmation", cp);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/change-password`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        s1.current.show();
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        s2.current.show();
      });
  };
  const submitForm = () => {
    const formdata = new FormData();
    formdata.append("name", username);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("password_confirmation", conf);
    formdata.append("avatar", user.avatar);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/update-user-cred`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(updateData(res.data.user));
        setMessage(res.data.message);
        s1.current.show();
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        s2.current.show();
      });
  };
  return (
    <motion.div
      initial={{ x: -200, opacity: 0 }}
      transition={{
        duration: 1.2,
      }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      className="flex flex-col justify-start items-center mt-2 lg:px-64 px-12"
    >
      <div className="border-b-2 w-full">
        <h4 className="text-gray-500">Your Profile Picture</h4>
        <div className="flex flex-row justify-start space-x-12 items-center py-8">
          <img
            src={user.avatar === "default.jpg" ? "/Avatar.png" : user.avatar}
            className="w-16 h-16 rounded-full object-cover mt-4"
          />
          <div class="image-upload" for="file-input">
            <form>
              <label
                for="file-input"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <h2 className="bg-red-600 rounded-lg px-8 py-2 text-gray-100 cursor-pointer h-full">
                  Upload New
                </h2>
              </label>
              <input
                id="file-input"
                type="file"
                className="hidden"
                onChange={(e) => updatePhoto2(e)}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full px-12 mt-8 space-y-12 ">
        <div className="flex flex-row justify-center gap-12 flex-wrap">
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Username</h4>
            <input
              type="text"
              value={username}
              className="w-full border-2 rounded-xl px-2"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Email</h4>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border-2 rounded-xl px-2"
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Password</h4>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border-2 rounded-xl px-2"
            />
          </div>
          <div className="lg:w-1/3 w-full flex-col space-y-2">
            <h4 className="text-gray-500">Password Confirmation</h4>
            <input
              type="password"
              onChange={(e) => setPass(e.target.value)}
              className="w-full border-2 rounded-xl px-2"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <button
            onClick={() => submitForm()}
            className="border-2 rounded-xl p-4 block mx-auto border-red-600 text-red-600 hover:bg-red-600 hover:text-gray-200 transition-all duration-150"
          >
            Update Profile
          </button>
        </div>

        <div>
          <h3 className="text-center tracking-widest text-xl">
            Change Password
          </h3>
          <div className="flex flex-col justify-center items-center">
            <div className="lg:w-1/3 w-full flex-col space-y-2">
              <h4 className="text-gray-500 text-center">New Password</h4>
              <input
                type="password"
                onChange={(e) => setNp(e.target.value)}
                className="w-full border-2 rounded-xl px-2"
              />
            </div>
            <div className="lg:w-1/3 w-full flex-col space-y-2">
              <h4 className="text-gray-500 text-center">
                Password Confirmation
              </h4>
              <input
                type="password"
                onChange={(e) => setCp(e.target.value)}
                className="w-full border-2 rounded-xl px-2"
              />
            </div>
            <div className="lg:w-1/3 w-full flex-col space-y-2">
              <h4 className="text-gray-500 text-center">Old Password</h4>
              <input
                type="password"
                onChange={(e) => setP(e.target.value)}
                className="w-full border-2 rounded-xl px-2"
              />
            </div>
            <div className="flex flex-row">
              <button
                onClick={() => changePassword()}
                className="border-2 mt-4 rounded-xl p-4 block mx-auto border-red-600 text-red-600 hover:bg-red-600 hover:text-gray-200 transition-all duration-150"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
        <Snackbar type="success" message={message} ref={s1} />
        <Snackbar type="error" message={message} ref={s2} />
      </div>
    </motion.div>
  );
}
